<template>
  <form-wrapper ref="form" reference="mainForm" boxed :form-title="$t('producer.titles.mainInformation')" @update:valid="$emit('update:valid', $event)">
    <template #fields>
      <v-col cols="12" class="ma-0 py-2">
        <!-- Nombre con estilo mejorado -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.name')}}</label>
          <span class="field-value">{{ item.name }}</span>
        </div>
        <v-text-field v-else
                      v-model="item.name"
                      :label="$t('producer.fields.name')"
                      required
                      :rules="rules.name"
                      outlined
                      dense
                      color="secondary"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      :disabled="!isEditable"
        >
          <template v-slot:label>
            {{$t('producer.fields.name')}} <strong class="red--text">*</strong>
          </template>
          <template v-slot:append>
            <span v-if="loading.name" class="field-value loading-placeholder mt-1">
              <v-progress-circular indeterminate color="secondary" size="20" width="3"></v-progress-circular>
            </span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="ma-0 py-2">
        <!-- CPF con estilo mejorado -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.cpf')}}</label>
          <span class="field-value">{{ item.cpf || '-' }}</span>
        </div>
        <v-text-field v-else
                      class="mr-1"
                      v-model="item.cpf"
                      :label="$t('producer.fields.cpf')"
                      placeholder="___.___.___-__"
                      outlined
                      dense
                      color="secondary"
                      v-mask="'###.###.###-##'"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      :disabled="!isEditable"nvm use
                      :rules="rules.cpf">
          <template v-slot:append>
            <span v-if="loading.cpf" class="field-value loading-placeholder mt-1">
              <v-progress-circular indeterminate color="secondary" size="20" width="3"></v-progress-circular>
            </span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="ma-0 py-2">
        <!-- RG con estilo mejorado -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.rg')}}</label>
          <span class="field-value">{{ item.rg || '-' }}</span>
        </div>
        <v-text-field v-else
                      class="ml-1"
                      v-model="item.rg"
                      :label="$t('producer.fields.rg')"
                      outlined
                      dense
                      color="secondary"
                      @input="toUpperCase()"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      :disabled="!isEditable">
          <template v-slot:append>
            <span v-if="loading.rg" class="field-value loading-placeholder mt-1">
              <v-progress-circular indeterminate color="secondary" size="20" width="3"></v-progress-circular>
            </span>
          </template>
        </v-text-field>
      </v-col>
    </template>
  </form-wrapper>
</template>

<script>
import FormWrapper from "@/components/common/FormWrapper.vue";
import {mapActions, mapGetters} from "vuex";
import i18n from "@/plugins/i18n";

export default {
  components: { FormWrapper },
  props: {
    item: {
      type: Object,
      require: true,
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    valid: true,
    loading: {
      cpf: false,
      rg: false,
      name: false
    },
    ruleResults: {
      cpf: true,
      rg: true,
      name: true
    }
  }),
  watch: {
    'item.cpf': _.debounce(function (newVal) {
      this.asyncCpfValidation(newVal);
    }, 500),
    'item.rg': _.debounce(function (newVal) {
      this.asyncRgValidation(newVal);
    }, 500),
    'item.name': _.debounce(function (newVal) {
      this.asyncNameValidation(newVal);
    }, 500)
  },
  computed: {
    ...mapGetters({
      requireRules: "general/requireRules",
      getRegexValidationRule: 'general/getRegexValidationRule'
    }),
    rules() {
      return {
        cpf: [
          ...this.getRegexValidationRule('cpf'),
          this.ruleResults.cpf
        ],
        rg: [
          ...this.getRegexValidationRule('rg'),
          this.ruleResults.rg
        ],
        name: [
          ...this.requireRules,
          this.ruleResults.name
        ]
      };
    }
  },
  methods: {
    ...mapActions({
      validateField: 'producer/validateField'
    }),
    asyncCpfValidation: async function(value) {
      this.loading.cpf = true;
      let validationData = { cpf: value };
      if (this.item.id) {
        validationData.id = this.item.id;
      }
      const response = await this.validateField(validationData);
      this.loading.cpf = false;
      this.ruleResults.cpf = response.cpf ? true : i18n.t('general.rules.cpfExist');
    },
    asyncRgValidation: async function(value) {
      this.loading.rg = true;
      let validationData = { rg: value };
      if (this.item.id) {
        validationData.id = this.item.id;
      }
      const response = await this.validateField(validationData);
      this.loading.rg = false;
      this.ruleResults.rg = response.rg ? true : i18n.t('general.rules.rgExist');
    },
    asyncNameValidation: async function(value) {
      this.loading.name = true;
      let validationData = { name: value };
      if (this.item.id) {
        validationData.id = this.item.id;
      }
      const response = await this.validateField(validationData);
      this.loading.name = false;
      this.ruleResults.name = response.name ? true : i18n.t('general.rules.nameExist');
    },
    toUpperCase() {
      if (this.item.rg)
        this.item.rg = this.item.rg.toUpperCase();
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
  },
  beforeDestroy() {
    this.$refs.form.$refs.mainForm.reset();
    console.log("MainSection Destroyed");
  }
};
</script>

<style scoped>
.display-field {
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: .2rem;
}
.field-label {
  font-weight: bold;
  margin-right: 5px;
}
.field-value {
  font-size: 16px;
}
</style>
