import { VCol } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form-wrapper',{ref:"form",attrs:{"reference":"generalForm","boxed":"","form-title":_vm.$t('producer.titles.generalInformation')},on:{"update:valid":function($event){return _vm.$emit('update:valid', $event)}},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c(VCol,{staticClass:"ma-0 py-2",attrs:{"cols":"12"}},[(!_vm.isEditable)?_c('div',{staticClass:"display-field"},[_c('label',{staticClass:"field-label"},[_vm._v(_vm._s(_vm.$t("producer.fields.locations")))]),(_vm.item.storageDevices.length > 0)?_c('ShowMoreComponent',{attrs:{"item":_vm.item,"secondary-field":"storageDevices.name"}}):_c('span',[_vm._v("-")])],1):_c('LocationFilters',{attrs:{"two-columns":true,"storage-label":_vm.$t('documents.fields_add.storage_device'),"location-label":_vm.$t('documents.fields.locations'),"storage-ref":"storageRef","location-ref":"locationRef","locations-selected":_vm.locations,"storages-selected":_vm.item.storageDevices,"show-origin":true,"show-destination":true,"autocomplete-props":{
                        color: 'secondary',
                        itemColor: 'secondary',
                        smallChips: true,
                        clearable: true,
                        multiple: true,
                        dense: true,
                        outlined: true
                      }},on:{"update:locations:origin":function($event){_vm.locations = $event},"update:storages:origin":function($event){_vm.item.storageDevices = $event},"on:valid-form":function($event){return _vm.$emit('update:valid', $event)}}})],1),_c(VCol,{staticClass:"ma-0 py-2",attrs:{"cols":"12"}},[(!_vm.isEditable)?_c('div',{staticClass:"display-field"},[_c('label',{staticClass:"field-label"},[_vm._v(_vm._s(_vm.$t('producer.fields.observations')))]),_c('span',{staticClass:"field-value"},[_vm._v(_vm._s(_vm.item.observations || '-'))])]):_c(VTextarea,{attrs:{"label":_vm.$t('producer.fields.observations'),"outlined":"","auto-grow":"","dense":"","color":"secondary","disabled":!_vm.isEditable},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==86)return null;return _vm.replace.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==86)return null;if(!$event.ctrlKey)return null;return _vm.replace.apply(null, arguments)}]},model:{value:(_vm.item.observations),callback:function ($$v) {_vm.$set(_vm.item, "observations", $$v)},expression:"item.observations"}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }