<template>
  <v-dialog v-model="dialog" :key="formKey" max-width="1024px" v-bind="dialogProps" persistent>
    <v-card elevation="0" tile width="1024px" rounded v-bind="dialogProps">
      <v-toolbar flat v-bind="toolbarProps" class="px-4">
        <v-toolbar-title class="font-weight-bold" style="font-size: 28px">{{ title }}</v-toolbar-title>
        <v-spacer />
        <slot name="actions"></slot>
      </v-toolbar>

      <v-tabs class="pa-5" vertical v-model="activeTab" height="100%" color="secondary">
        <div style="border-radius: .3rem; border: 1px solid #f1f1f1; max-height: 100%; background-color: rgba(243,243,243,0.94)">
          <v-tab class="d-flex justify-start align-center" v-for="(tab, index) in tabs" :key="index">
            <v-icon left>{{ tab.icon }}</v-icon>
            {{ tab.name }}
          </v-tab>
        </div>

        <v-tab-item eager v-for="(tab, index) in tabs" :key="index" :transition="false">
          <v-card flat class="mx-12 my-2" >
            <v-card-text>
              <slot :name="tab.slotName"></slot>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <v-card-actions class="white px-8 py-4">
      <v-spacer></v-spacer>
      <v-btn color="grey" text @click="closeDialog" :disabled="loading">{{ $t('general.buttons.cancel') }}</v-btn>
      <span v-if="!isEditable">
        <v-btn color="grey" text @click="changeEditingMode()">{{ $t('general.titles.editMode') }}
          <v-icon small class="ml-2">mdi-pencil</v-icon>
        </v-btn>
      </span>
      <span v-else>
        <v-btn color="secondary" text @click="saveData" :disabled="!valid" :loading="loading">{{ $t('general.buttons.save') }}</v-btn>
      </span>
    </v-card-actions>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    dialogProps: {
      type: Object,
      default: () => ({})
    },
    toolbarProps: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: 'Form Details'
    },
    tabs: {
      type: Array,
      default: () => ([])
    },
    valid: {
      type: Boolean,
      default: true
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeTab: null,
      formKey: 0
    };
  },
  mounted() {
    this.$root.$on('clear:form', () => {
      console.log('event: clear:form');
      this.clearForm();
    })
  },
  methods: {
    changeEditingMode() {
      this.$emit('mode:editable', true);
      this.$emit('validate:form');
    },
    clearForm() {
      this.activeTab = 0;
      this.formKey++;
    },
    closeDialog() {
      this.$emit('on:close');
    },
    saveData() {
      this.$emit('on:save');
    }
  },
  destroyed() {
    this.$root.$off('clear:form');
  }
};
</script>

<style scoped>
/* Agrega aquí estilos si es necesario */
</style>
