<template>
  <form-wrapper ref="form" reference="contactForm" boxed :form-title="$t('producer.titles.contactInformation')" @update:valid="$emit('update:valid', $event)">
    <template #fields>
      <v-col cols="12" md="6" class="ma-0 py-2">
        <!-- Teléfono con estilo mejorado -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.phone')}}</label>
          <span class="field-value">{{ item.phone || '-' }}</span>
        </div>
        <v-text-field v-else
                      class="mr-1"
                      v-model="item.phone"
                      :label="$t('producer.fields.phone')"
                      outlined
                      dense
                      color="secondary"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      :disabled="!isEditable"
                      v-mask="phoneMask"
                      :rules="phoneRules"
                      @input="updateMask(item.phone)">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="ma-0 py-2">
        <!-- Email con estilo mejorado -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.email')}}</label>
          <span class="field-value">{{ item.email || '-' }}</span>
        </div>
        <v-text-field v-else
                      class="ml-1"
                      v-model="item.email"
                      :label="$t('producer.fields.email')"
                      outlined
                      dense
                      color="secondary"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      :disabled="!isEditable"
                      :rules="emailRules">
        </v-text-field>
      </v-col>
    </template>
  </form-wrapper>
</template>

<script>
import FormWrapper from "@/components/common/FormWrapper.vue";
import {mapGetters} from "vuex";

export default {
  components: { FormWrapper },
  props: {
    item: {
      type: Object,
      require: true,
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    valid: true,
    phoneMask: "+55 (##) #####-####",
  }),
  computed: {
    ...mapGetters({
      requireRules: "general/requireRules",
      phoneRules: "general/phoneRules",
      emailRules: 'general/emailRules'
    })
  },
  methods: {
    updateMask(value) {
      if (value && value.length >= 6 && value[5] !== '(') {
        this.phoneMask = '+55 (##) #####-####';
      } else {
        this.phoneMask = '+55 (##) ####-####';
      }
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
  },
  beforeDestroy() {
    this.$refs.form.$refs.contactForm.reset();
    console.log("ContactSection Destroyed");
  }
};
</script>

<style scoped>
.display-field {
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: .2rem;
  display: flex;
  align-items: center;
}
.field-label {
  font-weight: bold;
  margin-right: 5px;
}
.field-value {
  font-size: 16px;
  flex-grow: 1;
}
</style>

