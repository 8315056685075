<template>
  <form-wrapper ref="form" reference="addressForm" boxed :form-title="$t('producer.titles.addressInformation')" @update:valid="$emit('update:valid', $event)">
    <template #fields>
      <v-col cols="12">
        <!-- Visualización de dirección 1 -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.address1')}}</label>
          <span class="field-value">{{ item.address1 || '-' }}</span>
        </div>
        <v-text-field v-else
                      v-model="item.address1"
                      :label="$t('producer.fields.address1')"
                      required
                      v-bind="fieldProps.address1.props"
                      background-color="white"
                      :disabled="!isEditable"
        />
      </v-col>
      <v-col cols="12">
        <!-- Visualización de dirección 2 -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.address2')}}</label>
          <span class="field-value">{{ item.address2 || '-' }}</span>
        </div>
        <v-text-field v-else
                      v-model="item.address2"
                      :label="$t('producer.fields.address2')"
                      v-bind="fieldProps.address2.props"
                      background-color="white"
                      :disabled="!isEditable"
        />
      </v-col>
      <v-col cols="12" md="6">
        <!-- Visualización de país con indicador de carga -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.country')}}</label>
          <span v-if="loadingCountries" class="field-value loading-placeholder">
            <v-progress-circular indeterminate color="secondary" size="20" width="3"></v-progress-circular>
          </span>
          <v-chip v-else-if="errorCountries" color="red" small>{{ $t('producer.errors.loadCountry') }}</v-chip>
          <span v-else class="field-value">{{ getCountryName(item.country) || '-' }}</span>
        </div>
        <!-- Selector de país si isEditable es true -->
        <v-select v-else
                  v-model="item.country"
                  :items="countries"
                  item-text="name"
                  item-value="name"
                  return-object
                  :label="$t('producer.fields.country')"
                  required
                  @change="handleCountryChange"
                  v-bind="fieldProps.country.props"
                  background-color="white"
                  :disabled="loadingCountries">
          <template v-slot:append>
            <span v-if="loadingCountries" class="field-value loading-placeholder mt-1">
              <v-progress-circular indeterminate color="secondary" size="20" width="3"></v-progress-circular>
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <!-- Visualización de estado con indicador de carga -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.state')}}</label>
          <span v-if="loadingStates" class="field-value loading-placeholder">
            <v-progress-circular indeterminate color="secondary" size="20" width="3"></v-progress-circular>
          </span>
          <v-chip v-else-if="errorStates" color="red" small>$t('producer.errors.loadState')</v-chip>
          <span v-else class="field-value">{{ getStateName(item.state) || '-' }}</span>
        </div>
        <v-select v-else
                  v-model="item.state"
                  :items="states"
                  item-text="name"
                  item-value="name"
                  return-object
                  :label="$t('producer.fields.state')"
                  required
                  @change="handleStateChange"
                  v-bind="fieldProps.state.props"
                  background-color="white"
                  :disabled="loadingStates || !item.country">
          <template v-slot:append>
              <span v-if="loadingStates" class="field-value loading-placeholder mt-1">
                <v-progress-circular indeterminate color="secondary" size="20" width="3"></v-progress-circular>
              </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.city')}}</label>
          <span v-if="loadingCities" class="field-value loading-placeholder">
            <v-progress-circular indeterminate color="secondary" size="20" width="3"></v-progress-circular>
          </span>
          <v-chip v-else-if="errorCities" color="red" small>$t('producer.errors.loadCity')</v-chip>
          <span v-else class="field-value">{{ getCityName(item.city) || '-' }}</span>
        </div>
        <v-select v-else
                  v-model="item.city"
                  :items="cities"
                  item-text="name"
                  item-value="name"
                  return-object
                  :label="$t('producer.fields.city')"
                  required
                  v-bind="fieldProps.city.props"
                  background-color="white"
                  :disabled="loadingCities || !item.state">
          <template v-slot:append>
              <span v-if="loadingCities" class="field-value loading-placeholder mt-1">
                <v-progress-circular indeterminate color="secondary" size="20" width="3"></v-progress-circular>
              </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <!-- Visualización de código postal -->
        <div v-if="!isEditable" class="display-field">
          <label class="field-label">{{$t('producer.fields.zipCode')}}</label>
          <span class="field-value">{{ item.zipCode || '-' }}</span>
        </div>
        <v-text-field v-else
                      v-model="item.zipCode"
                      :label="$t('producer.fields.zipCode')"
                      required
                      v-bind="fieldProps.zipCode.props"
                      background-color="white"
                      :disabled="!isEditable"
        />
      </v-col>
    </template>
  </form-wrapper>
</template>

<script>
import MapsServices from '@/services/GeolocationService';
import FormWrapper from "@/components/common/FormWrapper.vue";

export default {
  components: {FormWrapper},
  props: {
    fieldProps: {
      type: Object,
      default: () => ({
        address1: { props: {} },
        address2: { props: {} },
        country: { props: {} },
        state: { props: {} },
        city: { props: {} },
        zipCode: { props: {} }
      })
    },
    item: {
      type: Object,
      required: true
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    valid: true,
    countries: [],
    states: [],
    cities: [],
    loadingCountries: false,
    loadingStates: false,
    loadingCities: false,
    errorCountries: false,
    errorStates: false,
    errorCities: false
  }),
  async mounted() {
    await this.fetchCountries();
    console.log('fetchCountries')
    if (this.item.country) {
      console.log('fetchStates')
      this.item.country = this.countries.find(c => c.name === this.item.country);
      await this.fetchStates();
      this.item.state = this.states.find(s => s.name === this.item.state);
      if (this.item.state) {
        console.log('fetchCities')
        await this.fetchCities();
        this.item.city = this.cities.find(s => s.name === this.item.city);
      }
    }
  },
  computed: {
    selectedCountry: {
      get() {
        return this.item.country;
      },
      set(newValue) {
        this.item.country = newValue;
        this.fetchStates();
      }
    },
    selectedState: {
      get() {
        return this.item.state;
      },
      set(newValue) {
        this.item.state = newValue;
        this.fetchCities();
      }
    }
  },
  methods: {
    async fetchCountries() {
      this.loadingCountries = true;
      this.errorCountries = false; // Reset the error state
      try {
        const response = await MapsServices.fetchAllCountries();
        this.countries = response.data.countries;
      } catch (error) {
        this.errorCountries = true; // Set the error state
        console.error("Failed to fetch countries", error);
      } finally {
        this.loadingCountries = false;
      }
    },

    async fetchStates() {
      this.loadingStates = true;
      this.errorStates = false;
      try {
        const response = await MapsServices.fetchStatesByCountry(this.item.country.iso2);
        this.states = response.data.states;
      } catch (error) {
        this.errorStates = true;
        console.error("Failed to fetch states", error);
      } finally {
        this.loadingStates = false;
      }
    },

    async fetchCities() {
      this.loadingCities = true;
      this.errorCities = false;
      try {
        const response = await MapsServices.fetchCitiesOfState(this.item.country.iso2, this.item.state.iso2);
        this.cities = response.data.cities;
      } catch (error) {
        this.errorCities = true;
        console.error("Failed to fetch cities", error);
      } finally {
        this.loadingCities = false;
      }
    },
    getCountryName(item) {
      if (typeof item === 'string' )
        item = { name: item };

      if (item) {
        const country = this.countries.find(c => c.name === item.name);
        return country ? country.name ? country.name : country : null;
      }

      return '-'
    },
    getStateName(item) {
      if (typeof item === 'string' )
        item = { name: item };

      if (item) {
        const state = this.states.find(s => s.name === item.name);
        return state ? state.name ? state.name : state : null;
      }

      return '-'
    },
    getCityName(item) {
      if (typeof item === 'string' )
        item = { name: item };

      if (item) {
        const city = this.cities.find(c => c.name === item.name);
        return city ? city.name ? city.name : city : null;
      }

      return '-'
    },
    handleCountryChange(newCountry) {
      this.resetStates();
      this.item.country = newCountry;
      this.fetchStates();
    },

    handleStateChange(newState) {
      this.resetCities();
      this.item.state = newState;
      this.fetchCities();
    },
    resetStates() {
      this.item.state = null;
      this.states = [];
      this.resetCities();
    },

    resetCities() {
      this.item.city = null;
      this.cities = [];
    },
  },

  beforeDestroy() {
    this.$refs.form.$refs.addressForm.reset();
    console.log("AddressSection Destroyed");
  }
};
</script>


<style scoped>
.display-field {
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: .2rem;
  display: flex;
  flex-direction: column;
}
.field-label {
  font-weight: bold;
  margin-right: 5px;
}
.field-value {
  font-size: 16px;
  margin-bottom: 5px;
}
.loading-placeholder {
  display: flex;
  align-items: center;
  height: 20px; /* Alinea el tamaño del indicador con el tamaño de la fuente */
}
</style>

