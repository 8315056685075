import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tab-dialog',{attrs:{"title":_vm.formTitle,"dialog":_vm.showTabDialog,"tabs":_vm.tabsConfig,"valid":_vm.valid,"is-editable":_vm.isEditable,"loading":_vm.loading,"dialog-props":{
    height: 520
  },"toolbar-props":{
    color: _vm.editedItem.isActive ? 'secondary' : 'grey lighten-4',
    dark: _vm.editedItem.isActive
  }},on:{"on:save":_vm.handleSave,"on:close":function($event){return _vm.close()},"mode:editable":function($event){return _vm.$emit('mode:editable', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VSwitch,{staticClass:"px-2 py-2",attrs:{"disabled":!_vm.isEditable,"color":"white","inset":"","hide-details":""},on:{"change":function($event){}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{ref:"label",staticClass:"font-weight-bold ma-0 pa-0 mt-1 mx-1",class:{ 'white--text': _vm.editedItem.isActive }},[_vm._v(_vm._s(!_vm.editedItem.isActive ? _vm.$t("producer.fields.inactive") : _vm.$t("producer.fields.isActive"))+" ")])]},proxy:true}]),model:{value:(_vm.editedItem.isActive),callback:function ($$v) {_vm.$set(_vm.editedItem, "isActive", $$v)},expression:"editedItem.isActive"}})]},proxy:true},{key:"mainInformation",fn:function(){return [_c('main-section',{attrs:{"item":_vm.editedItem,"is-editable":_vm.isEditable},on:{"update:valid":function($event){_vm.valid=$event}}})]},proxy:true},{key:"addressInformation",fn:function(){return [_c('a-address-form',{attrs:{"fieldProps":_vm.fieldPropsAddress,"boxed":true,"item":_vm.editedItem,"is-editable":_vm.isEditable},on:{"update:valid":function($event){_vm.valid=$event}}})]},proxy:true},{key:"contactInformation",fn:function(){return [_c('contact-section',{attrs:{"item":_vm.editedItem,"is-editable":_vm.isEditable},on:{"update:valid":function($event){_vm.valid=$event}}})]},proxy:true},{key:"generalInformation",fn:function(){return [_c('general-section',{attrs:{"item":_vm.editedItem,"is-editable":_vm.isEditable},on:{"update:valid":function($event){_vm.valid=$event}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }